import "@splidejs/splide/dist/css/splide.min.css";
import "./assets/fonts/fonts.css";
import "./styles/main.css";
import "./styles/sass/index.scss";

import "./styles/layouts/blockgridlayout.css";
import "../css/index.css";

import "flowbite";
//import gsap from "gsap";
//import ScrollTrigger from "gsap/ScrollTrigger";
//import { gsap } from "gsap";
//import { ScrollTrigger } from "gsap/ScrollTrigger";
/*gsap.registerPlugin(ScrollTrigger);*/
import _, { map } from 'underscore';
import "./scripts/global/cookie.js";
import "./scripts/global/gsap.js";
import "./scripts/modules/navigation.js";
import "./scripts/modules/masthead.js";
import "./scripts/modules/marquee.js";
import "./scripts/modules/salesforceForms.js";
import "./scripts/modules/slide-in-content.js";
import "./scripts/modules/slide-up-darken.js";
import "./scripts/modules/hero-image-row.js";
import "./scripts/modules/vertical-carousel.js";
import "./scripts/modules/storyCardsGalleryOverflowRow.js";
import "./scripts/modules/dab-cta.js";
import "./scripts/modules/overflow-gallary.js";
import "./scripts/modules/slideUpFeatureCardsGallaryRow.js";
import "./scripts/modules/slideUpFeatureCardsComponentRow.js";
import "./scripts/modules/featureAccordionRow.js";
import "./scripts/modules/featureAccordionContainerRow.js";
import "./scripts/modules/featureOverflowVerticalScrollComponent.js";
import "./scripts/modules/boatsTabContainer.js";
import "./scripts/modules/boatFinder.js";
//import "./scripts/modules/BoatsAPI.js";
import "./scripts/modules/scrollingHeroBannerRow.js";
//import "./scripts/modules/heroVideoRow.js";
import "./scripts/modules/comparePage.js";
import "./scripts/modules/allBoats.js";
import "./scripts/modules/stickyNav.js";
import "./scripts/modules/input-slider.js";
//import "./scripts/modules/storiesAPI.js";
import "./scripts/modules/google.map.js";
import "./scripts/modules/knowledgeCenterPage.js";
import "./scripts/modules/faqLandingPage.js";
import "./scripts/modules/tab.js";
import "./scripts/modules/dealerLocator.js";
import "./scripts/modules/search.js";
import "./scripts/modules/heroBanner.js";



import ('./scripts/modules/carousel.js');
// import ('./scripts/modules/accordion.js');
/** Expose onclick functions here
 *
 */
export {
    playPause,
  } from "./scripts/modules/overflow-gallary.js";
export { showModal,showToolTip} from "./scripts/modules/comparePage.js";
export { loadMoreStories } from "./scripts/modules/storiesPage.js";
export { updateToMobile } from "./scripts/modules/dab-cta.js";
export { updateActivity, navigateTo, onChickletClick,updateAthleteList/*, updateEventLink*/ } from "./scripts/modules/allBoats.js";
export {showAllManuals} from "./scripts/modules/ownersPage.js";
export {handleLoadMore} from "./scripts/modules/knowledgeCenterPage.js";
export { updateSelectedTab } from "./scripts/modules/tab.js";