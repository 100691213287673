import { gsap } from "gsap";
export function updateToMobile() {
  let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  let isMobile = windowWidth < 800;
  const dabCTA = new gsap.timeline({
    scrollTrigger: {
      trigger: "#dab-cta",
      start: isMobile ? 'top 50%' : '50% bottom',
      end: isMobile ? 'bottom 120px' : 'bottom bottom',
      scrub: 1,
      markers: false
    }
  });
  if (isMobile) {
    dabCTA.to("#dab-cta-image", {
      xPercent: -25,
      ease: "linear"
    });
  } else {
    dabCTA.from("#dab-cta-image", {
      yPercent: 40
    });
  }
}
updateToMobile();
window.addEventListener('resize', function () {
  updateToMobile();
});

